<all-summary [summaryArr]="this.summaryData()"></all-summary>
<show-bars [chartInfo]="this.valueChart()"></show-bars>
<app-order-lists
  [displayedColumns]="this.displayOrderColumns"
  [orderlists]="this.orderList()"
></app-order-lists>
<app-user-lists
  [displayedColumns]="this.displayUserColumns"
  [userlists]="this.userlists()"
></app-user-lists>
