<mat-tab-group mat-stretch-tabs>
    <mat-tab label="Orders">
        <mat-card-content>
            <div class="example-container mat-elevation-z8">
                <table mat-table [dataSource]="dataSource">

                    <ng-container matColumnDef="restaurantname">
                        <th mat-header-cell *matHeaderCellDef>Restaurant Name</th>
                        <td mat-cell *matCellDef="let element">{{ element.restaurant }}</td>
                    </ng-container>

                    <ng-container matColumnDef="amount">
                        <th mat-header-cell *matHeaderCellDef>Amount</th>
                        <td mat-cell *matCellDef="let element">{{ element.amount }} ₹</td>
                    </ng-container>

                    <ng-container matColumnDef="payment">
                        <th mat-header-cell *matHeaderCellDef>Payment</th>
                        <td mat-cell *matCellDef="let element">{{ element.payment }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="OrdersDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: OrdersDisplayedColumns"></tr>
                </table>
            </div>
        </mat-card-content>
    </mat-tab>
    <mat-tab label="address"> <mat-card-content>
            <div class="example-container mat-elevation-z8">
                <table mat-table [dataSource]="[]">

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>id</th>
                        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                    </ng-container>

                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef>title</th>
                        <td mat-cell *matCellDef="let element">{{ element.title }} ₹</td>
                    </ng-container>

                    <ng-container matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef>address</th>
                        <td mat-cell *matCellDef="let element">{{ element.address }}</td>
                    </ng-container>

                    <ng-container matColumnDef="house">
                        <th mat-header-cell *matHeaderCellDef>house</th>
                        <td mat-cell *matCellDef="let element">{{ element.house }}</td>
                    </ng-container>

                    <ng-container matColumnDef="landmark">
                        <th mat-header-cell *matHeaderCellDef>landmark</th>
                        <td mat-cell *matCellDef="let element">{{ element.landmark }}</td>
                    </ng-container>
                    <ng-container matColumnDef="pincode">
                        <th mat-header-cell *matHeaderCellDef>pincode</th>
                        <td mat-cell *matCellDef="let element">{{ element.pincode }}</td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>actions</th>
                        <td mat-cell *matCellDef="let element">{{ element.actions }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="addressDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: addressDisplayedColumns"></tr>
                </table>
            </div>
        </mat-card-content> </mat-tab>
    <mat-tab label="Reviews"> <mat-card-content>
            <div class="example-container mat-elevation-z8">
                <table mat-table [dataSource]="[]">

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef>id</th>
                        <td mat-cell *matCellDef="let element">{{ element.id }}</td>
                    </ng-container>

                    <ng-container matColumnDef="from">
                        <th mat-header-cell *matHeaderCellDef>from</th>
                        <td mat-cell *matCellDef="let element">{{ element.from }} ₹</td>
                    </ng-container>

                    <ng-container matColumnDef="on">
                        <th mat-header-cell *matHeaderCellDef>on</th>
                        <td mat-cell *matCellDef="let element">{{ element.on }}</td>
                    </ng-container>
                    <ng-container matColumnDef="rating">
                        <th mat-header-cell *matHeaderCellDef>rating</th>
                        <td mat-cell *matCellDef="let element">{{ element.rating }}</td>
                    </ng-container>
                    <ng-container matColumnDef="comment">
                        <th mat-header-cell *matHeaderCellDef>comment</th>
                        <td mat-cell *matCellDef="let element">{{ element.comment }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="ReviewsDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: ReviewsDisplayedColumns"></tr>
                </table>
            </div>
        </mat-card-content> </mat-tab>
</mat-tab-group>