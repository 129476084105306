import { Inject, Injectable } from '@angular/core';

import { ResultModel, UserModel } from '@frontend/shared/models';
import { HttpHelperService } from '@frontend/shared/services/';
import { AddressModel } from 'lib-shared-models/models/address.model';
import { map, Observable, take } from 'rxjs';
import { DashboardEnvironmentType } from '../../environments/env.type';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  private env: DashboardEnvironmentType;
  constructor(
    private httpService: HttpHelperService,
    @Inject('APP_CONFIG')
    config: {
      env: DashboardEnvironmentType;
    },
  ) {
    this.env = config.env;
  }

  public getUserList() {
    return this.httpService.getList<UserModel>(this.env.httpUserListUrl);
  }

  public fetchUserRequestWithUserById(
    id: string | null,
  ): Observable<UserModel> {
    return this.httpService.getList<UserModel>(this.env.httpUserListUrl).pipe(
      map((el) =>
        id ? el.filter((res) => res.id == id)[0] : ({ id } as UserModel),
      ),
      take(1),
    );
  }

  public updateUser(
    firstName: string,
    lastName: string,
    mobileNo: string,
  ): Observable<string> {
    const body = JSON.stringify({ firstName, lastName, mobileNo });
    return this.httpService
      .post<string>(this.env.httpUserUpdate, body)
      .pipe(map((response) => response));
  }

  public addAddress(address: AddressModel): Observable<ResultModel> {
    const body = { ...address };
    return this.httpService
      .post<ResultModel>(this.env.httpAddUserAddress, body)
      .pipe(map((response) => response));
  }

  public updateAddress(address: AddressModel): Observable<ResultModel> {
    const body = { ...address };
    return this.httpService
      .post<ResultModel>(this.env.httpUpdateUserAddress, body)
      .pipe(map((response) => response));
  }

  public getAddressById(id: string): Observable<AddressModel> {
    const params = { id };
    return this.httpService
      .get<AddressModel>(this.env.httpGetAddressById, params)
      .pipe(map((response) => response));
  }
}
