import { Inject, Injectable } from '@angular/core';
import { HttpHelperService } from '@frontend/shared/services/';
import { map, Observable, of, take } from 'rxjs';
import { DriverModel } from '../../../../../libs/shared/models/src/models/driver.model';
import { DashboardEnvironmentType } from '../../environments/env.type';

@Injectable({
  providedIn: 'root',
})
export class DriverApiService {
  private env: DashboardEnvironmentType;
  constructor(
    private httpService: HttpHelperService,

    @Inject('APP_CONFIG')
    config: {
      env: DashboardEnvironmentType;
    },
  ) {
    this.env = config.env;
  }

  public getDriverList() {
    return of<DriverModel[]>(data);
  }

  public fetchDriverById(id: string | null): Observable<DriverModel | null> {
    return of<DriverModel[]>(data).pipe(
      map((el) => (id ? el.filter((res) => res.id == id)[0] : null)),
      take(1),
    );
  }

  public deleteDriver(id: string) {
    const index = data.map((el) => el.id).indexOf(id);
    if (index > -1) {
      data.splice(index, 1);
    }
    return of(data);
  }

  public updateDriver(driver: DriverModel) {
    const isexit = data.map((el) => el.id).includes(driver.id);
    if (isexit) {
      data.map((el) => el.id === driver.id && (el = driver));
    } else {
      data.push(driver);
    }
    const index = data.map((el) => el.id).indexOf(driver.id);
    return of(data[index]);
  }

  public updateDriverStatus(id: string, status: DriverModel['status']) {
    data.map((el) => el.id === id && (el.status = status));
    return of(data);
  }
}
const data = getDrivers();

export function makeId() {
  let id = '';
  let characters =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < 12; i++) {
    id += characters.charAt(Math.floor(Math.random() * 36));
  }
  return id;
}

function getDrivers(): DriverModel[] {
  return Array(50)
    .fill(1)
    .map((n, i) => n + i)
    .map((el) => {
      return {
        id: makeId() + el,
        firstName: `John${el}`,
        lastName: `Doe${el}`,
        email: `john.doe${el}@example.com`,
        mobileNo: `+911234567${el}`,
        gender: 'Male',
        status: 'ACTIVE',
        profileId: `1234567${el}`,
        address: el + ' Main St',
        location: { latitude: 37.7749 + el, longitude: -122.4194 + el },
        dateOfBirth: `01/01/19${el}`,
        drivingLicenseNo: 'DL12345678' + el,
        vehicleNumber: 'MH28BU12' + el,
        city: 'San Francisco',
        state: 'California',
        pincode: 94103 + el,
        vehicleType: 'Car',
        identityProof: {
          AADHAARCARD: '1234567890',
          PANCARD: 'P1234567890',
          DRIVINGLICENSE: 'DL1234567890',
        },
      };
    });
}
