<div class="order">
  <div class="order-header">
    <div class="order-header-titel">orders Details</div>
    <button class="order-header-print__button">print</button>
  </div>

  <div class="order-profile">
    <div class="order-profile-img">
      <img src="assets/img/img_avatar.png" alt="" class="avatar" />
    </div>
    <div class="order-profile-details">
      <div class="order-profile-details--name">
        {{ user()?.firstName + ' ' + user()?.lastName }}
      </div>
      <div class="order-profile-details--date">
        {{ order()?.date | date: 'dd MMM yyyy - hh:mm' }}
      </div>
    </div>
  </div>
  <div class="order-details">
    <div class="order-details-header__titel">Orders</div>
    <hr />
    <ng-container
      *ngTemplateOutlet="
        orderDetailContainer;
        context: {
          orderDetail: this.orderDetaillist(),
        }
      "
    ></ng-container>
    <ng-container
      *ngTemplateOutlet="
        orderOthersinfo;
        context: {
          otherInfo: this.orderDetailOtherInfo(),
        }
      "
    ></ng-container>
    <div class="order-details-total">
      <div class="order-details-total--title">Amount to collect</div>
      <div class="order-details-total--amount">{{ order()?.total }} $</div>
    </div>
    <hr />
    <div class="order-details-footer">
      <div class="order-details-footer--title">Delivery to</div>
      <div class="order-details-footer--landmark">
        {{ order()?.deliveryTo }}
      </div>
    </div>
  </div>
  <div class="order-address">
    {{ order()?.address }}
  </div>
  <div class="order-basic-details">
    <div class="order-basic-details-header__titel">Basic details</div>
    <hr />
    <div class="order-basic-details-info">
      <div class="order-basic-details-info--title">Order ID</div>
      <div class="order-basic-details-info--value">{{ order()?.id }}</div>
    </div>
    <div class="order-basic-details-info">
      <div class="order-basic-details-info--title">Payment Method</div>
      <div class="order-basic-details-info--value">{{ order()?.payment }}</div>
    </div>
    <div class="order-basic-details-info">
      <div class="order-basic-details-info--title">Delivery on</div>
      <div class="order-basic-details-info--value">
        {{ order()?.deliveryOn }}
      </div>
    </div>
    <hr />
  </div>
  @if (user()?.mobileNo) {
    <div class="order-contacts">
      <div class="order-contacts--info">{{ user()?.mobileNo }}</div>
      <button class="order-contacts--button">call</button>
    </div>
    <hr />
  }
  @if (user()?.email) {
    <div class="order-contacts">
      <div class="order-contacts--info">
        {{ user()?.email }}
      </div>
      <button class="order-contacts--button">email</button>
    </div>
  }
  <hr />
  <div class="order-tracking">
    <div class="order-tracking--title">order Tracking</div>
    <hr />
    <ng-container
      *ngTemplateOutlet="
        orderTracking;
        context: {
          tracking: this.orderTrack(),
        }
      "
    ></ng-container>
    <hr />
  </div>
  <hr />
  <div class="update__tracking">
    <fieldset class="fieldinput">
      <input #statusInput placeholder="update status..." />
      <button (click)="updateTrack(statusInput.value)" class="submit">
        update status
      </button>
    </fieldset>
  </div>
</div>

<ng-template #orderDetailContainer let-orderDetail="orderDetail">
  @for (i of orderDetail; track i) {
    <div class="order-info">
      <div class="order-info--item">
        {{ i.item }} {{ i.weightQuantity }} {{ i.weight }} {{ i.price }} rs
      </div>
      <div class="order-info--quantity">&#10005;{{ i.quantity }}</div>
    </div>
    <hr />
  }
</ng-template>

<ng-template #orderOthersinfo let-otherInfo="otherInfo">
  @for (i of otherInfo; track i) {
    <div class="order-other-info">
      <div class="order-other-info--title">{{ i.title }}</div>
      <div class="order-other-info--amount">{{ i.amount }}$</div>
    </div>
    <hr />
  }
</ng-template>

<ng-template #orderTracking let-tracking="tracking">
  <div class="order-track">
    @for (t of tracking; track t) {
      <div class="order-track-step">
        <div class="order-track-status">
          <span class="order-track-status-line"></span>
          <span class="order-track-status-dot"></span>
        </div>
        <div class="order-track-text">
          <p class="order-track-text-stat">{{ t.title }}</p>
          <span class="order-track-text-sub">{{
            t.date | date: 'dd MMM yyyy - hh:mm'
          }}</span>
        </div>
      </div>
    }
  </div>
</ng-template>
