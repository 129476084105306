import { Component, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AuthApiService } from 'lib-shared-services/services/auth/auth.api.service';
import { map } from 'rxjs';

@Component({
  standalone: true,
  imports: [RouterModule],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.less',
})
export class AppComponent implements OnInit {
  title = 'dashboard';

  constructor(private authApiService: AuthApiService,
    private router: Router
  ) {

  }
  ngOnInit(): void {
    this.authApiService.getCurrentLoginUser().pipe(map(session => {
      //TODO : FIX 
      if (!session.isRestaurantValidated) {
        this.router.navigate(['/restaurant-registration']);
      }
    })).subscribe()

  }

}
