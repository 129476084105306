@if (this.dataSource()) {
  <div class="container mat-elevation-z8">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title class="cus-mat-card-title">Customers</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        @if (this.showSearch()) {
          <div class="header">
            <div class="searchbar">
              <input
                class="searchinput"
                type="text"
                matInput
                (keyup)="this.applySearch($event)"
                placeholder="Search user"
              />
              <button
                class="searchsubmit"
                type="submit"
                name="searchQuerySubmit"
              >
                <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                  <path
                    fill="#666666"
                    d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        }
        <div class="example-container mat-elevation-z8">
          <div class="example-table-container">
            <table
              matTableResponsive
              mat-table
              [dataSource]="this.dataSource()"
            >
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">
                  <img src="assets/img/img_avatar.png" alt="" class="avatar" />
                  {{ element.firstName }} {{ element.lastName }}
                </td>
              </ng-container>

              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element">{{ element.email }}</td>
              </ng-container>

              <ng-container matColumnDef="mobileNo">
                <th mat-header-cell *matHeaderCellDef>MobileNo</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.mobileNo }}
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>Action</th>
                <td mat-cell *matCellDef="let element">
                  <button mat-button (click)="this.getRecord(element.id)">
                    View
                  </button>
                </td>
              </ng-container>
              <tr
                mat-header-row
                sticky
                *matHeaderRowDef="displayedColumns()"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns()"
              ></tr>
            </table>
            @if (this.usePaginator()) {
              <mat-paginator
                [pageSizeOptions]="[10, 20, 50]"
                showFirstLastButtons
                aria-label="Select page"
              >
              </mat-paginator>
            }
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
}
