import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthApiService } from 'lib-shared-services/services/auth/auth.api.service';

@Component({
  standalone: true,
  selector: 'app-otp',
  imports: [CommonModule,ReactiveFormsModule, FormsModule],
  template: `
  <div class="title">
    Please enter OTP recieved on email
  </div>

  <div class="otp-container">
  <input 
    *ngFor="let otpItem of otpArray; let i = index" 
    type="text" 
    maxlength="1" 
    autocomplete="off"
    class="otp-input" 
    [(ngModel)]="otpValues[i]" 
    (keydown)="onKeyDown($event, i)"
    (input)="onInputChange($event, i)"
    [id]="'otp' + i"
    #otpInput
  />
</div>

<button class="submit-btn" (click)="submitOtp()">Submit OTP</button>

<div class="message" *ngIf="message">{{ message }}</div>
`,
  styles: `
  .otp-container {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.title{
  text-align: center;
  padding:20px;
  font-size: 20px;
  font-weight: 600;
}

.otp-input {
  width: 42px;
  height: 40px;
  font-size: 24px;
  text-align: center;
  margin: 0 5px;
}

.submit-btn {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  color: #fff;
  background: #009579;
  font-size: 1.0rem;
  transition: 0.4s;

  font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 6px;
    outline: none;
}

.message {
  text-align: center;
  margin-top: 5px;
  color: red;
}
`,
})
export class OtpComponent{ 

  otpArray: number[] = [0, 1, 2, 3, 4, 5]; // This controls the number of input fields
  otpValues: string[] = new Array(6).fill(''); // Store each OTP digit
  message: string = '';
  @Input()
  email:string="";

  constructor(private authService:AuthApiService, private router: Router,
  ){}

  // Move focus to the next field or handle backspace
  onKeyDown(event: KeyboardEvent, index: number) {
    const target = event.target as HTMLInputElement;

    if (event.key === 'Backspace' && index > 0 && !target.value) {
      // If backspace and current input is empty, move to the previous field
      const prevInput = document.getElementById('otp' + (index - 1)) as HTMLInputElement;
      if (prevInput) prevInput.focus();
    }
  }

  // Handle input change and auto-focus to the next field
  onInputChange(event: Event, index: number) {
    const target = event.target as HTMLInputElement;
    const value = target.value;

    if (value && index < this.otpArray.length - 1) {
      const nextInput = document.getElementById('otp' + (index + 1)) as HTMLInputElement;
      if (nextInput) nextInput.focus();
    }
  }

  // Submit OTP function
  submitOtp() {
    const otp = this.otpValues.join('');
    if (otp.length === this.otpArray.length) {
            this.authService.submitOtp( this.email, otp ).subscribe({
              next: (response) => {
                //TODO : FIX 
                if (!response.isRestaurantValidated){
                              this.router.navigate(['/restaurant-registration']);
                }else{
                  // On successful login, navigate to the home or dashboard
                  this.router.navigate(['/home']);
                }
              },
              error: (err) => {
                this.message = err?.error?.message || 'OTP submission failed';
              }
            });
      // You can make an HTTP call here to submit the OTP
    } else {
      this.message = 'Please enter a valid 6-digit OTP.';
    }
  }
}
