import { Inject, Injectable } from '@angular/core';
import { HttpHelperService } from '@frontend/shared/services/';
import { OrderModel } from 'libs/shared/models/src/models/order.model';
import { map, Observable, of, take } from 'rxjs';
import { DashboardEnvironmentType } from '../../environments/env.type';

@Injectable({
  providedIn: 'root',
})
export class OrderApiService {
  private env: DashboardEnvironmentType;
  constructor(private httpService:HttpHelperService,
        @Inject("APP_CONFIG") config: {
    env: DashboardEnvironmentType;
  }
  ){
    this.env = config.env;
  }
  public getOrderList() {
    return of<OrderModel[]>(orderModelData);
  }

  public fetchOrderById(id: string | null): Observable<OrderModel> {
    return of<OrderModel[]>(orderModelData).pipe(
      map((el) =>
        id ? el.filter((res) => res.id == id)[0] : ({} as OrderModel),
      ),
      take(1),
    );
  }
}

const orderModelData: OrderModel[] = [
  {
    userName: 'Vicky Rao',
    restaurant: 'Hydrogen Cafe',
    amount: 750,
    payment: 'CARD',
    id: '5790191097244755',
    userId: '12aad2ac-0927-4561-a087-c65fb2ccf304',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 19109,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Mary Smith',
    restaurant: 'Malaka Spice',
    amount: 650,
    payment: 'CARD',
    id: '24631580196156055',
    userId: 'eed9eb27-08ff-4760-9a49-45b76f31746b',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 15801,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'María Pérez',
    restaurant: 'The Sassy Spoon',
    amount: 250,
    payment: 'CASH',
    id: '557369928060099',
    userId: '0e5c5d03-aa14-4a50-a281-59635f6a8587',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 69928,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Li Ping',
    restaurant: 'Blue Nile Restaurant',
    amount: 850,
    payment: 'CARD',
    id: '2625497760519917',
    userId: '8cf1aec6-eb7b-458d-9419-4d089c7e5dd8',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 49776,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Hemant Lal',
    restaurant: 'Persian Darbar',
    amount: 1000,
    payment: 'CASH',
    id: '9330534315626577',
    userId: '236a8fed-6b23-45bb-a749-9316bd36e17b',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 53431,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Anne Williams',
    restaurant: 'Baan Tao',
    amount: 1250,
    payment: 'CARD',
    id: '5686906076276361',
    userId: '43cd4990-8a1f-4fd8-aee5-558a6c8f7fc2',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 90607,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Vicky Rao',
    restaurant: 'Hydrogen Cafe',
    amount: 750,
    payment: 'CARD',
    id: '6169547670353235',
    userId: '59984238-a375-4c12-b39a-3fdc8c6e9904',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 54767,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Mary Smith',
    restaurant: 'Malaka Spice',
    amount: 650,
    payment: 'CARD',
    id: '3454908867201736',
    userId: '8b1f97be-d8c5-46ea-981d-6936385addfe',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 90886,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'María Pérez',
    restaurant: 'The Sassy Spoon',
    amount: 250,
    payment: 'CASH',
    id: '5495594733692826',
    userId: '59984238-a375-4c12-b39a-3fdc8c6e9904',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 59473,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Li Ping',
    restaurant: 'Blue Nile Restaurant',
    amount: 850,
    payment: 'CARD',
    id: '1984224727101016',
    userId: 'ee2e98e5-bc82-4a76-b3ed-151853182f56',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 22472,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Hemant Lal',
    restaurant: 'Persian Darbar',
    amount: 1000,
    payment: 'CASH',
    id: '22044557288729028',
    userId: 'a4217238-4bef-493d-b00d-288e50ea12d4',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 45572,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Anne Williams',
    restaurant: 'Baan Tao',
    amount: 1250,
    payment: 'CARD',
    id: '8227806175491836',
    userId: '806f520a-774b-46cc-8ebb-e16032f96461',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 80617,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Vicky Rao',
    restaurant: 'Hydrogen Cafe',
    amount: 750,
    payment: 'CARD',
    id: '1553504352353896',
    userId: 'a3d4f04e-c1d1-4154-88b4-0155d12537a0',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 50435,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Mary Smith',
    restaurant: 'Malaka Spice',
    amount: 650,
    payment: 'CARD',
    id: '7884225667424436',
    userId: '96f77050-1303-4c48-8036-648c6f8fe951',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 22566,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'María Pérez',
    restaurant: 'The Sassy Spoon',
    amount: 250,
    payment: 'CASH',
    id: '36136545001490394',
    userId: '3cdbab5d-c998-491f-8fe2-de03921906ae',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 65450,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Li Ping',
    restaurant: 'Blue Nile Restaurant',
    amount: 850,
    payment: 'CARD',
    id: '3172306652939345',
    userId: 'e78331ff-07b7-4faa-8ded-8863348a202b',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 30665,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Hemant Lal',
    restaurant: 'Persian Darbar',
    amount: 1000,
    payment: 'CASH',
    id: '30914746837799867',
    userId: '43cd4990-8a1f-4fd8-aee5-558a6c8f7fc2',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 47468,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Anne Williams',
    restaurant: 'Baan Tao',
    amount: 1250,
    payment: 'CARD',
    id: '382569537734351',
    userId: 'ee2e98e5-bc82-4a76-b3ed-151853182f56',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 69537,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Vicky Rao',
    restaurant: 'Hydrogen Cafe',
    amount: 750,
    payment: 'CARD',
    id: '6722610564468838',
    userId: '3cdbab5d-c998-491f-8fe2-de03921906ae',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 61056,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'Mary Smith',
    restaurant: 'Malaka Spice',
    amount: 650,
    payment: 'CARD',
    id: '3088831566170098',
    userId: '3455e6bf-4902-43ed-b93b-f7c07c9734ce',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 83156,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
  {
    userName: 'María Pérez',
    restaurant: 'The Sassy Spoon',
    amount: 250,
    payment: 'CASH',
    id: '8513085802078504',
    userId: '0e5c5d03-aa14-4a50-a281-59635f6a8587',
    shoppingCartModel: [
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 12,
        quantity: 10,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 5,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 89,
        quantity: 2,
      },
      {
        item: 'red chilli',
        weightQuantity: '250',
        weight: 'gm',
        price: 34,
        quantity: 3,
      },
    ],
    total: 8580,
    deliveryCharge: 0,
    tax: 0,
    discount: 0,
    walletDiscount: 0,
    address: 'Vitthal park, muktainagar, near kalpattaru, Jalgaon- 425001',
    deliveryOn: 'done',
    deliveryTo: 'Home',
    date: '2024-10-06T10:56:57.217Z',
  },
];
