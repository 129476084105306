import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatError, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { FileuploadComponent, LoadingSpinnerComponent } from '@frontend/shared/ui/';
import { UserApiService } from 'apps/dashboard/src/app/api-services/user.api.service';
import { AddressModel } from 'lib-shared-models/models/address.model';
import { SessionModel } from 'lib-shared-models/models/session.model';
import { AuthApiService } from 'lib-shared-services/services/auth/auth.api.service';
import { RestaurantApiService } from 'lib-shared-services/services/restaurant/restaurant.api.service';
import { fiif } from 'lib-shared-utils/lib/shared-utils/rxjs-util';
import { catchError, EMPTY, map, of, switchMap } from 'rxjs';

@Component({
  selector: 'user-info',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatError,
    MatSelectModule,
    FileuploadComponent,
    LoadingSpinnerComponent
  ],
  templateUrl: './rest-registration.component.html',
  styleUrl: './rest-registration.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestRegistrationComponent implements OnInit {
  restauranttypes = [
    { id: 'FINE_DINING', value: 'FINE DINING' },
    { id: 'CASUAL_DINING', value: 'CASUAL DINING' },
    { id: 'FAST_FOOD_DINING', value: 'FAST FOOD DINING' },
    { id: 'CAFE_COFFEE_SHOP', value: 'CAFE COFFEE SHOP' },
    { id: 'BAKERIES_SHOP', value: 'BAKERIES SHOP' },
    { id: 'BAR_AND_DINING', value: 'BAR AND DINING' },
    { id: 'DELIVERY_ONLY', value: 'DELIVERY ONLY' },
  ];

  restImg: File | null = null;
  restMenu: File | null = null;
  userFormGroup: FormGroup;
  restaurantFormGroup: FormGroup;
  addressFormGroup: FormGroup;
  imageFormGroup: FormGroup;
  restImgPreview: string | null = null;
  restImgErrorMessage: string | null = null;
  isLoading = false;



  private _formBuilder = inject(FormBuilder);
  //private _snackBar = inject(MatSnackBar);

  public session: SessionModel | undefined;
  constructor(
    private router: Router,
    private authApiService: AuthApiService,
    private userApiService: UserApiService,
    private restaurantApiService: RestaurantApiService,
    private destroyRef: DestroyRef,
    private snackBar: MatSnackBar,
  ) {
    this.userFormGroup = this._formBuilder.group({
      firstName: [this.session?.firstName, Validators.required],
      lastName: [this.session?.firstName, Validators.required],
      mobileNo: [
        this.session?.mobileNo,
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
      email: [
        { value: this.session?.emailId, disabled: true },
        [Validators.required, Validators.email],
      ],
    });

    this.restaurantFormGroup = this._formBuilder.group({
      restaurantName: ['', [Validators.required]],
      description: ['', [Validators.required]],
      restauranttype: [''],
      gstin: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[0-9A-Z]{1}',
          ),
        ],
      ],
    });

    this.addressFormGroup = this._formBuilder.group({
      addressLine1: ['', Validators.required],
      addressLine2: ['', Validators.required],
      landmark: [''],
      pincode: [
        '',
        [Validators.required, Validators.pattern('^[1-9][0-9]{5}$')],
      ],
      city: ['', Validators.required],
      state: ['', Validators.required],
      isPrimaryAddress: [true],
      latitude: [0, Validators.required],
      longitude: [0, Validators.required],
    });

    this.imageFormGroup = this._formBuilder.group({
      restImg: [null, [Validators.required, this.fileValidator]],
      restMenu: [null, [Validators.required, this.fileValidator]],
      restFSSI : [null, [Validators.required, this.fileValidator]],
    });
  }

  fileValidator(control: any) {
    const files = control.value;
    if (!files || files.length === 0) {
      return { required: true };
    }
    return null;
  }

  get userF() {
    return this.userFormGroup.controls;
  }

  get addressF() {
    return this.addressFormGroup.controls;
  }

  get restaurantF() {
    return this.restaurantFormGroup.controls;
  }

  isLinear = true;

  ngOnInit(): void {
    this.authApiService
      .getCurrentLoginUser()
      .pipe(
        switchMap((session)=> {
          if (session.isRestaurantValidated) {
            this.router.navigate(['/home']);
          }

          this.userFormGroup.patchValue({
            firstName: session.firstName,
            lastName: session.lastName,
            email: session.emailId,
            mobileNo: session.mobileNo,
          });
          return of(session)
        }),
        switchMap((session) => {
          this.session = session;
          if (session && session?.id){
           return this.fetchRestaurantDetails(session.id);
          }
          return EMPTY;
        },
 
    )
      )
      .subscribe();
  }


  public fetchRestaurantDetails(userId: string){
           return this.restaurantApiService
          .getRestaurantDetailsByUserId(userId)
          .pipe(
            switchMap((res) => {
              this.restaurantFormGroup.patchValue({
                restaurantName: res.restaurantName,
                description: res.description,
                //restauranttype: ,
                gstin: res.gstin,
              });

              if (this.session) {
                this.session.restaurantId = res.id;
                this.authApiService.updateSession(this.session);
              }
              if (res.address){
             if (this.session) {
                this.session.addressId = res.address?.id;
                this.authApiService.updateSession(this.session);
              }
              this.addressFormGroup.patchValue({
                      addressLine1: res?.address.addressLine1,
                      addressLine2: res.address.addressLine2,
                      landmark: res.address.landmark,
                      pincode: res.address.pincode,
                      city: res.address.city,
                      state: res.address.state,
                      isPrimaryAddress: res.address.isPrimaryAddress,
                      latitude: res.address.latitude,
                      longitude: res.address.longitude,
                    });
                  }

   
              return of(res);
            }),
          )
  }

  onSubmit() {
    if (this.userFormGroup.valid && this.addressFormGroup.valid) {
      // Combine form data
      const combinedData = {
        ...this.userFormGroup.value,
        ...this.addressFormGroup.value,
      };

      console.log('Submitted Data:', combinedData);

      // Handle submission logic
      alert('Form submitted successfully! Check console for combined data.');
    } else {
      alert('Please complete all required fields before submitting.');
    }
  }
  handleUploadStatus(event: { success: boolean; message: string, file?:File }, id: number): void {        
    const call = (imgType: string)=> {
        if (this.session?.restaurantId && event?.file){
            //this.restaurantApiService.uploadImgForRestaurant(this.session?.restaurantId, event.file , imgType).subscribe();
        }
    }

     switch (id) {
      case 1:
         call("RESTAURANT_IMG");
         this.imageFormGroup.get('restImg')?.setValue('uploaded');

        break;
       case 2:
        call("RESTAURANT_MENU_IMG");
        this.imageFormGroup.get('restMenu')?.setValue('uploaded');

        break;
        case 3:
          call("RESTAURANT_FSSI_IMG");
          this.imageFormGroup.get('restFSSI')?.setValue('uploaded');
        break;
     }
    
    this.imageFormGroup.updateValueAndValidity();

  }


  nextUpload(stepper: MatStepper) {
    if (this.restImg && this.restMenu) {
      const formData = new FormData();
      formData.append('file', this.restImg);
      formData.append('file', this.restMenu);
    }
  }

  processNext = (stepper: MatStepper) => {
      stepper.next();
 };

  public nextSaveUser(stepper: MatStepper) {


    if (this.userFormGroup.dirty && this.userFormGroup.touched) {
      const { firstName, lastName, mobileNo } = this.userFormGroup.value;
      this.userApiService
        .updateUser(firstName, lastName, mobileNo)
        .pipe(
          map((_) => {
            if (this.session){
            this.session.firstName =firstName;
            this.session.lastName =lastName;
            this.session.mobileNo =mobileNo;
            this.authApiService.updateSession(this.session);
            }

            this.openSnackBar('User details saved!', 'Ok');
            this.processNext(stepper);
          }),
          catchError((e) => {
            this.handleError();
            return EMPTY;
          }),
        )
        .subscribe();
    } else {
            this.processNext(stepper);
    }
  }

 public nextSaveRestaurant(stepper: MatStepper) {
    if (this.restaurantFormGroup.dirty && this.restaurantFormGroup.touched) {
      const { restaurantName, description, restType, gstin } =
        this.restaurantFormGroup.value;

      fiif(
        () =>
          this.session?.restaurantId != null &&
          this.session?.restaurantId != '',
        () =>
          this.restaurantApiService.updateRestaurant(
            this.session?.restaurantId || '',
            restaurantName,
            description,
            restType,
            gstin,
          ),
        () =>
          this.restaurantApiService.AddRestaurant(
            restaurantName,
            description,
            restType,
            gstin,
          ),
      )
        .pipe(
          map((data) => {
            if (this.session) {
              this.session.restaurantId = data.result;
              this.authApiService.updateSession(this.session);
            }
            this.openSnackBar('Restaurant details saved!', 'Ok');
            this.processNext(stepper);
          }),
          catchError((e) => {
            this.handleError();
            return EMPTY;
          }),
        )
        .subscribe();
    } else {
        this.processNext(stepper);
    }
  }

  public nextSaveAddress(stepper: MatStepper) {
    if (this.addressFormGroup.dirty) {
      const address: AddressModel = this.addressFormGroup.value;

            fiif(
        () => !!this.session?.addressId,
        () =>{ address.id = this.session?.addressId;
          address.isPrimaryAddress=true;
          return this.userApiService.updateAddress(address)
        },
        () =>this.userApiService.addAddress(address)
        ).pipe(
      map((data) => {
            if (this.session && !this.session?.addressId) {
              this.session.addressId = data.result || "";
              this.authApiService.updateSession(this.session);
            }
          this.openSnackBar('Address details saved!', 'Ok');
          this.processNext(stepper);
        }),
        catchError((e) => {
          this.handleError();
          return EMPTY;
        }),
      ).subscribe();
    }else{
      this.processNext(stepper);

    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      horizontalPosition: 'center',
      //verticalPosition: 'top',
    });
  }

  handleError() {
    this.snackBar.open('Error while saving data. Please try again !', 'Ok', {
      duration: 3000,
      horizontalPosition: 'center',
      //verticalPosition: 'bottom',
    });
  }
}
