import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { OrderModel } from '../../../../../../../libs/shared/models/src/models/order.model';
import { OrderApiService } from '../../../api-services/order.api.service';
import { OrderListsComponent } from '../../dashboard-management/stateless-components/order-lists/order-lists.component';

@Component({
  selector: 'order-details',
  standalone: true,
  imports: [CommonModule, OrderListsComponent],
  templateUrl: 'order-details.component.html',
  styleUrl: './order-details.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailsComponent {
  displayOrderColumns = signal<string[]>([
    'id',
    'username',
    'restaurant',
    'amount',
    'payment',
    'action',
  ]);
  orderlist = signal<OrderModel[]>([]);

  constructor(private orderApiService: OrderApiService) {
    this.orderApiService.getOrderList().subscribe({
      next: (order: OrderModel[]) => {
        this.orderlist.set([...order]);
      },
    });
  }
}
