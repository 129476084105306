import { CommonModule } from '@angular/common';

import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { UserModel } from '@frontend/shared/models';
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { OrderModel } from '../../../../../../../libs/shared/models/src/models/order.model';
import { OrderApiService } from '../../../api-services/order.api.service';
import { UserApiService } from '../../../api-services/user.api.service';
import { AllSummaryComponent } from '../../dashboard-management/smart-components/all-summary/all-summary.component';
import {
  ChartInfo,
  ShowBarsComponent,
} from '../../dashboard-management/smart-components/show-bars/show-bars.component';
import { OrderListsComponent } from '../../dashboard-management/stateless-components/order-lists/order-lists.component';
import { UserListsComponent } from '../../dashboard-management/stateless-components/user-lists/user-lists.component';

export interface SummaryData {
  title: string;
  totalValue: string;
}

@Component({
  selector: 'control-panel',
  standalone: true,
  imports: [
    CommonModule,
    ControlPanelComponent,
    AllSummaryComponent,
    ShowBarsComponent,
    UserListsComponent,
    OrderListsComponent,
  ],
  templateUrl: 'control-panel.component.html',
  styleUrl: './control-panel.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlPanelComponent {
  public userlists = signal<UserModel[]>([]);

  public orderList = signal<OrderModel[]>([]);
  public displayUserColumns = ['name', 'email', 'mobileNo', 'action'];
  public displayOrderColumns = [
    'username',
    'restaurant',
    'amount',
    'payment',
    'action',
  ];
  constructor(
    private userApiService: UserApiService,
    private orderApiService: OrderApiService,
  ) {
    this.userApiService.getUserList().subscribe({
      next: (users: UserModel[]) => {
        // Update signal with the new list of users
        this.userlists.set([...users]); // Spread to avoid mutation issues
      },
    });
    this.orderApiService.getOrderList().subscribe({
      next: (order: OrderModel[]) => {
        this.orderList.set([...order.slice(0, 7)]);
      },
    });
  }

  public getWeekMonthSubTitle = (useMonth = false) => {
    const today = new Date();
    const start = useMonth ? startOfMonth(today) : startOfWeek(today);
    const end = useMonth ? endOfMonth(today) : endOfWeek(today);

    const formattedStart = format(start, 'd');
    const formattedEnd = format(end, 'd MMMM');

    return `${formattedStart} - ${formattedEnd}`;
  };

  summaryData = signal<SummaryData[]>([
    {
      title: 'Total Orders',
      totalValue: '12,000',
    },
    {
      title: 'Total Users',
      totalValue: '1,000',
    },
    {
      title: 'Total Stores',
      totalValue: '1,500',
    },
    {
      title: 'Total Products',
      totalValue: '1,050',
    },
  ]);
  valueChart = signal<ChartInfo[]>([
    {
      id: 'TODAY',
      title: 'Today Orders stats',
      subTitle: format(new Date(), 'd MMMM'),
      barchart: {
        title: 'Orders',
        period: 'TODAY',
        start: new Date(),
        end: new Date(),
        dataValues: [4],
      },
    },
    {
      id: 'WEEK',
      title: 'Weekly Orders stats',
      subTitle: this.getWeekMonthSubTitle(),
      barchart: {
        title: 'Orders',
        period: 'WEEK',
        start: new Date(format(startOfWeek(new Date()), 'yyyy-MM-dd')),
        end: new Date(format(endOfWeek(new Date()), 'yyyy-MM-dd')),
        dataValues: [44, 32, 50, 22, 25, 40, 80],
      },
    },
    {
      id: 'MONTH',
      title: 'Monthly Orders stats',
      subTitle: this.getWeekMonthSubTitle(true),
      barchart: {
        title: 'Orders',
        period: 'MONTH',
        start: new Date(format(startOfMonth(new Date()), 'yyyy-MM-dd')),
        end: new Date(format(endOfMonth(new Date()), 'yyyy-MM-dd')),
        dataValues: [
          10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 100, 90, 80, 70, 60, 50, 40,
          30, 20, 10, 10, 20, 30, 40, 50, 60, 70, 80, 90, 110, 0,
        ],
      },
    },
  ]);
}
