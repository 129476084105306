import { CommonModule } from '@angular/common';
import { AfterContentInit, Component } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AuthApiService } from 'lib-shared-services/services/auth/auth.api.service';
import { OtpComponent } from '../otp/otp.component';
import { LoadingSpinnerComponent } from '../spinner/spinner.component';
import { LoginFormComponent } from './login-form/login.form.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OtpComponent,
    IonicModule,
    LoginFormComponent,
    LoadingSpinnerComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent implements AfterContentInit {
  form!: FormGroup;
  registerform!: FormGroup;
  loading = false;
  submitted = false;
  registersubmitted = false;
  showOtp = false;
  email = '';
  passwordVisible: boolean = false;
  passwordVisibleSignUp = false;
  errorMessage: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthApiService,
  ) {}

  ngAfterContentInit() {
    this.form = this.formBuilder.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          this.passwordPatternValidator,
        ],
      ],
    });

    this.registerform = this.formBuilder.group(
      {
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/),
          ],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            this.passwordPatternValidator,
          ],
        ],
        confirmpassword: ['', [Validators.required]],
      },
      { validator: this.passwordMatchValidator },
    );
  }

  get passwordMatchError() {
    return (
      this.registerform.getError('mismatch') &&
      this.registerform.get('confirmpassword')?.touched
    );
  }

  passwordPatternValidator(control: AbstractControl) {
    const password = control.value;

    if (!password) {
      return null;
    }
    const hasUpperCase = /[A-Z]/.test(password);
    const hasDigit = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (!hasUpperCase) {
      return { noUpperCase: true };
    }
    if (!hasDigit) {
      return { noDigit: true };
    }
    if (!hasSpecialChar) {
      return { noSpecialChar: true };
    }
    return null;
  }

  passwordMatchValidator(g: FormGroup): { [key: string]: boolean } | null {
    return g.get('password')?.value === g.get('confirmpassword')?.value
      ? null
      : { mismatch: true };
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }
  get rf() {
    return this.registerform.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    const { email, password } = this.form.value;
    this.loginApiCall(email, password);
  }

  loginApiCall(email: string, password: string) {
    this.errorMessage = '';

    this.authService.login(email, password).subscribe({
      next: (response) => {
        //TODO : FIX 
        if (!response.isRestaurantValidated){
          this.router.navigate(['/restaurant-registration']);
        }else{
          this.router.navigate(['/home']);
        }
      },
      error: (err) => {
        this.errorMessage =
          err?.error?.message || 'Login failed. Please try again.';
      },
    });
  }

  onSignUpSubmit() {
    this.errorMessage = '';
    this.registersubmitted = true;

    // stop here if form is invalid
    if (this.registerform.invalid) {
      return;
    }

    this.loading = true;
    const { email, password } = this.registerform.value;

    // Call the login method from AuthService
    this.authService.register(email, password).subscribe({
      next: (response) => {
        if (response.isAccountVerified) {
          this.loginApiCall(email, password);
          return;
        }
        this.email = email;
        this.showOtp = true;
      },
      error: (err) => {
        // Handle login failure, show error message
        this.errorMessage =
          err?.error?.message || 'Registration failed. Please try again.';
      },
    });
  }

  togglePasswordVisibility(): void {
    this.passwordVisible = !this.passwordVisible;
  }
  togglePasswordSignUpVisibility(): void {
    this.passwordVisibleSignUp = !this.passwordVisibleSignUp;
  }
}
