import { EnvironmentType, RouteMetadata } from '@frontend/shared/utils/type.ts';

export type DashboardEnvironmentType = EnvironmentType & DashboardAppConfig;

export type DashboardAppConfig = {
  httpUserListUrl: RouteMetadata;
  httpUserUpdate: RouteMetadata;
};

export const defaultEnvironemntConfig: DashboardEnvironmentType = {
  production: false,
  baseUrl: 'http://localhost:3000/',
  apiVersion: 'v1', // Optional: if you want to include versioning in your API URL
  httpAuthUrl: {
    route: '/auth/login',
    isPublic: true,
  },
  httpSignUpEmailUrl: {
    route: '/auth/signup/email',
    isPublic: true,
  },
  httpSignUpMobileUrl: {
    route: '/auth/signup/mobile',
    isPublic: true,
  },
  httpValidateEmailOtpUrl: {
    route: '/auth/validate/emailotp',
    isPublic: true,
  },
  httpValidateMobileOtpUrl: {
    route: '/auth/validate/mobileotp',
    isPublic: true,
  },
  httpUserListUrl: {
    route: '/user/lists',
    isPublic: false,
  },
  httpUserUrl: {
    route: '/user/',
    isPublic: false,
  },
  httpAuthRefreshTokenUrl: {
    route: '/auth/refresh-token',
    isPublic: true,
  },
  httpUserUpdate: {
    route: '/user/update',
    isPublic: false,
  },
  httpRestaurantAdd: {
    route: '/restaurant/add',
    isPublic: false,
  },
  httpRestaurantUpdate: {
    route: '/restaurant/update',
    isPublic: false,
  },
  httpFetchRestaurantDetails: {
    route: '/restaurant/detailsbyId',
    isPublic: false,
  },
  httpAddUserAddress: {
    route: '/user/add/address',
    isPublic: false,
  },

  httpUpdateUserAddress: {
    route: '/user/update/address',
    isPublic: false,
  },
  httpGetAddressById: {
    route: '/user/getAddressById',
    isPublic: false,
  },
  httpUploadImgRestaurant:{
    route: '/restaurant/upload',
    isPublic: false,
  },
  httpFetchRestaurantDetailsByUserId:{
    route: '/restaurant/detailsbyUserId',
    isPublic: false,
  }
};
