import { JsonSerializer } from 'lib-shared-utils/lib/shared-utils/json.util';
import {
  OnlyProperties,
  Serializable,
} from 'lib-shared-utils/lib/shared-utils/type';

export class SessionModel implements Serializable {
  @JsonSerializer.serializable()
  public id?: string;

  @JsonSerializer.serializable()
  public restaurantId?: string;

  @JsonSerializer.serializable()
  public riderId?: string;

  @JsonSerializer.serializable()
  public firstName?: string;

  @JsonSerializer.serializable()
  public lastName?: string;

  @JsonSerializer.serializable()
  public restaurantName?: string;

  @JsonSerializer.serializable()
  public emailId?: string;

  @JsonSerializer.serializable()
  public profilePic?: string;

  @JsonSerializer.serializable()
  public mobileNo?: string;

  @JsonSerializer.serializable()
  public Role?: string;

  @JsonSerializer.serializable()
  public isRestaurantValidated = false;

  @JsonSerializer.serializable()
  public addressId?: string;

  constructor(payload?: Partial<OnlyProperties<SessionModel>>) {
    if (payload) {
      Object.assign(this, JsonSerializer.deserialize(payload, SessionModel));
    }
  }
}
