import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  signal,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'cover-page',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './cover-page.component.html',
  styleUrl: './cover-page.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverPageComponent {
  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
  ) {}

  registrationForm = signal(
    this.fb.group({
      file: [null as any],
    }),
  );

  @ViewChild('fileInput') el: ElementRef = null!;
  imageUrl = signal<string | ArrayBuffer | null>('');
  editFile = signal<boolean>(true);
  removeUpload = signal<boolean>(false);

  uploadFile(event: any) {
    let reader = new FileReader();
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageUrl.update(() => reader.result);
        this.registrationForm.update((el) => {
          el.patchValue({
            file: reader.result,
          });
          return el;
        });
        this.editFile.update(() => false);
        this.removeUpload.update(() => true);
      };
      this.cd.markForCheck();
    }
  }
  removeUploadedFile() {
    const newFileList = Array.from(this.el.nativeElement.files);
    this.imageUrl.update(() => '');
    this.editFile.update(() => true);
    this.removeUpload.update(() => false);
    this.registrationForm.update((el) => {
      el.patchValue({
        file: [null],
      });
      return el;
    });
  }
  onSubmit() {
    if (!this.registrationForm().valid) {
      console.log('Please put img');
    } else {
      console.log(this.registrationForm().value);
    }
  }
}
