import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RestaurantModel } from 'lib-shared-models/models/restaurant.model';
import { ResultModel } from 'lib-shared-models/models/result.model';
import { EnvironmentType } from 'lib-shared-utils/lib/shared-utils/type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelperService } from '../helper/http.helper.service';

@Injectable({
  providedIn: 'root',
})
export class RestaurantApiService {
  private env: EnvironmentType;

  constructor(
    private router: Router,
    private httpService: HttpHelperService,
    @Inject('APP_CONFIG')
    config: {
      env: EnvironmentType;
    },
  ) {
    this.env = config.env;
  }

  public AddRestaurant(
    restaurantName: string,
    description: string,
    restType: string[],
    gstin: string,
  ): Observable<ResultModel> {
    const body = JSON.stringify({
      restaurantName,
      description,
      restType,
      gstin,
    });
    return this.httpService
      .post<ResultModel>(this.env.httpRestaurantAdd, body)
      .pipe(map((response) => response));
  }

  public uploadImgForRestaurant(restaurantId: string, file:File, imageType: string )
  : Observable<ResultModel>{

    const formData = new FormData();
    formData.append('file', file);
    formData.append('restaurantId', restaurantId);
    formData.append('imageType', imageType);

    console.log("formData : ",formData.get('file'))
    const body = JSON.stringify({  restaurantId, file:formData.get('file'), imageType });

    return this.httpService
      .post<ResultModel>(this.env.httpUploadImgRestaurant, body)
      .pipe(map((response) => response));
  }

  uploadFile(restaurantId: string, file:File, imageType: string ): Observable<any> {
    const formData = new FormData();
    formData.append('restaurantId', restaurantId);
    formData.append('file', file, file.name);  // Append the file to the FormData
    formData.append('imageType', imageType);
    console.log(formData.get('file')); // Debug: Check if file is added to FormData

    //const body = {restaurantId, imageType}
    return this.httpService
      .post<ResultModel>(this.env.httpUploadImgRestaurant, formData, )
      .pipe(map((response) => response));
  }

  public updateRestaurant(
    id: string,
    restaurantName: string,
    description: string,
    restType: string[],
    gstin: string,
  ): Observable<ResultModel> {
    const body = JSON.stringify({
      id,
      restaurantName,
      description,
      restType,
      gstin,
    });
    return this.httpService
      .post<ResultModel>(this.env.httpRestaurantUpdate, body)
      .pipe(map((response) => response));
  }

  public getRestaurantDetails(
    restaurantId: string,
  ): Observable<RestaurantModel> {
    const params = { restaurantId };
    return this.httpService
      .get<RestaurantModel>(this.env.httpFetchRestaurantDetails, params)
      .pipe(map((response) => response));
  }

  public getRestaurantDetailsByUserId(
    userId: string,
  ): Observable<RestaurantModel> {
    const params = { userId };
    return this.httpService
      .get<RestaurantModel>(this.env.httpFetchRestaurantDetailsByUserId, params)
      .pipe(map((response) => response));
  }
}
