<section [ngClass]="this.showMenuDiv() ? 'expanded' : 'back'">
  <div class="header">
    <img src="assets/img/logo.png" alt="your logo " />
  </div>
  <nav>
    <div class="titel">main</div>
    <li>
      <a routerLink="/home" routerLinkActive="menu-active">
        <ion-icon name="home">></ion-icon>
        Home
      </a>
    </li>
    <li>
      <a routerLink="/home" routerLinkActive="menu-active">
        <ion-icon name="storefront-outline">></ion-icon>
        Cities
      </a>
    </li>
    <li>
      <a routerLink="/home" routerLinkActive="menu-active">
        <ion-icon name="storefront-outline">></ion-icon>
        Stores
      </a>
    </li>
    <li>
      <a routerLink="/home/users-panel" routerLinkActive="menu-active">
        <ion-icon name="people-outline">></ion-icon>
        Users
      </a>
    </li>
    <li>
      <a routerLink="/home/drivers-panel" routerLinkActive="menu-active">
        <ion-icon name="bicycle-outline">></ion-icon>
        Drivers
      </a>
    </li>
    <li>
      <a routerLink="/home/orders-panel" routerLinkActive="menu-active">
        <ion-icon name="reorder-three-outline">></ion-icon>
        Orders
      </a>
    </li>
    <li>
      <a routerLink="/home" routerLinkActive="menu-active">
        <ion-icon name="albums-outline">></ion-icon>
        Categories
      </a>
    </li>
    <li>
      <a routerLink="/home" routerLinkActive="menu-active">
        <ion-icon name="albums-outline">></ion-icon>
        Sub Categories
      </a>
    </li>
    <li>
      <a routerLink="/home" routerLinkActive="menu-active">
        <ion-icon name="storefront-outline">></ion-icon>
        Products
      </a>
    </li>
    <li>
      <a routerLink="/home" routerLinkActive="menu-active">
        <ion-icon name="storefront-outline">></ion-icon>
        Payments
      </a>
    </li>
    <li><a class="full"></a></li>
  </nav>
  <footer>{{ '<' }}</footer>
</section>
