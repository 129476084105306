<app-loading-spinner></app-loading-spinner>

<div class="container">

  <mat-horizontal-stepper [linear]="true" #stepper>
    <mat-step [stepControl]="userFormGroup">
      <form [formGroup]="userFormGroup">
        <ng-template matStepLabel>Add User Details</ng-template>
        <mat-form-field appearance="outline">
          <mat-label>Owner First Name</mat-label>
          <input matInput formControlName="firstName" required />
          @if (userF['firstName'].errors?.['required']) {
          <mat-error>Please enter first name</mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Owner Last Name</mat-label>
          <input matInput formControlName="lastName" required />
          @if (userF['lastName'].errors?.['required']) {
          <mat-error>Please enter last name</mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput type="email" formControlName="email" />
          @if (userF['email'].errors?.['required'] || userF['email'].errors?.['email']) {
          <mat-error>Please enter a valid email</mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Mobile No</mat-label>
          <input matInput formControlName="mobileNo" required />
          @if (userF['mobileNo'].errors?.['required'] || userF['mobileNo'].errors?.['pattern']) {
          <mat-error>Please enter a valid mobile no</mat-error>
          }
        </mat-form-field>
        <div>
          <button mat-button (click)="nextSaveUser(stepper)" [disabled]="!userFormGroup.valid">Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="restaurantFormGroup">
      <form [formGroup]="restaurantFormGroup">
        <ng-template matStepLabel>Add Restaurant Details</ng-template>
        <mat-form-field appearance="outline">
          <mat-label>Restaurant Name</mat-label>
          <input matInput formControlName="restaurantName" required />
          @if (restaurantF['restaurantName'].errors?.['required']) {
          <mat-error>Please enter restaurant name</mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>GSTIN</mat-label>
          <input matInput formControlName="gstin" required />
          @if (restaurantF['gstin'].errors?.['required']) {
          <mat-error>Please enter GSTIN details</mat-error>
          }
          @if (restaurantF['gstin'].errors?.['pattern']) {
          <mat-error>Please enter correct GSTIN details for e.g. 22XXXXX0000X0Z0 </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <textarea matInput formControlName="description"></textarea>
          @if (restaurantF['description'].errors?.['required']) {
          <mat-error>Please enter description </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Select Type</mat-label>
          <mat-select formControlName="restauranttype" multiple>
            @for (type of restauranttypes; track type) {
            <mat-option [value]="type.id">{{type.value}}</mat-option>
            }
          </mat-select>
        </mat-form-field>

        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button (click)="nextSaveRestaurant(stepper)" [disabled]="!restaurantFormGroup.valid">Next</button>
        </div>
      </form>
    </mat-step>

    <mat-step [stepControl]="addressFormGroup">
      <form [formGroup]="addressFormGroup">
        <ng-template matStepLabel>Add Address</ng-template>
        <mat-form-field appearance="outline">
          <mat-label>Address Line 1</mat-label>
          <input matInput formControlName="addressLine1" required />
          @if (addressF['addressLine1'].errors?.['required']) {
          <mat-error>Please enter address line 1 </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Address Line 2</mat-label>
          <input matInput formControlName="addressLine2" required />
          @if (addressF['addressLine2'].errors?.['required']) {
          <mat-error>Please enter address line 2 </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Landmark</mat-label>
          <input matInput formControlName="landmark" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Pin Code</mat-label>
          <input matInput formControlName="pincode" required />
          @if (addressF['pincode'].errors?.['required'] || addressF['pincode'].errors?.['pattern']) {
          <mat-error>Please enter valid pincode </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input matInput formControlName="city" required />
          @if (addressF['city'].errors?.['required']) {
          <mat-error>Please enter city </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>state</mat-label>
          <input matInput formControlName="state" required />
          @if (addressF['state'].errors?.['required']) {
          <mat-error>Please enter state </mat-error>
          }
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>choose on map</mat-label>
          <input matInput />
        </mat-form-field>
        <div>
          <button mat-button matStepperPrevious>Back</button>
          <button mat-button [disabled]="!addressFormGroup.valid" (click)="nextSaveAddress(stepper)">Next</button>
        </div>
      </form>
    </mat-step>
    <mat-step [stepControl]="imageFormGroup">
      <form [formGroup]="imageFormGroup">

        <ng-template matStepLabel>Upload Documents</ng-template>


        <div class="container">
          <div class="flex-container">
            <div class="flex-item">
              <lib-fileupload label="Restaurant Image" [chooserId]="1"
                (uploadStatus)="handleUploadStatus($event, 1)"></lib-fileupload>
            </div>
            <div class="flex-item">
              <lib-fileupload label="Menu Image" [chooserId]="2"
                (uploadStatus)="handleUploadStatus($event, 2)"></lib-fileupload>
            </div>

            <div class="flex-item">
              <lib-fileupload label="FSSI Image" [chooserId]="3"
                (uploadStatus)="handleUploadStatus($event, 3)"></lib-fileupload>
            </div>
          </div>


          <button mat-button matStepperPrevious>Back</button>
          <button mat-button (click)="nextUpload(stepper)" [disabled]="!imageFormGroup.valid">Next</button>
        </div>
      </form>
    </mat-step>


    <mat-step>
      <ng-template matStepLabel>Final Step: Submit</ng-template>
      <div>
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button (click)="onSubmit()">Submit</button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>