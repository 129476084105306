<app-loading-spinner></app-loading-spinner>

<div class="main-container">
<div class="container">
    <div *ngIf="errorMessage" class="alert">
      {{ errorMessage }}
    </div>

@if(!this.showOtp){
    <div class="glass-body">
      <input type="checkbox" id="check">

            <div class="form-block login">
              <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <h2>Login</h2>
              <div *ngIf="submitted && f['email'].errors" class="error-message">
                <div *ngIf="f['email'].errors">Please provide proper email</div>
            </div>   
            <input autofocus type="email" formControlName="email" class="input-box" placeholder="Enter your email"
                [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
     

                <div *ngIf="submitted && f['password'].errors" class="error-message">
                  <!-- <div *ngIf="f['password'].errors">Password is required</div>-->
                   <ul>
                     <li *ngIf="f['password']?.errors?.['required']" >Password must be at least 8 characters long.</li>
                     <li *ngIf="f['password']?.errors?.['noUpperCase']">Password must contain at least one uppercase letter.</li>
                     <li *ngIf="f['password']?.errors?.['noDigit']">Password must contain at least one digit.</li>
                     <li *ngIf="f['password']?.errors?.['noSpecialChar']">Password must contain at least one special character.</li>
                   </ul>  
               </div>   

            <div class="password-container">
              <input [type]="passwordVisible ? 'text' : 'password'"
              placeholder="Enter your password" formControlName="password" class="input-box"
              autocomplete="off"
              [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
              <span class="toggle-password toggle-password-child1" (click)="togglePasswordVisibility()">
                <span class="eye" *ngIf="!passwordVisible">
                  <ion-icon name="eye-outline"></ion-icon>
                </span>  <!-- Closed Eye Icon -->
                <span class="eye-open" *ngIf="passwordVisible">
                  <ion-icon name="eye-off-outline"></ion-icon>
                </span>  <!-- Open Eye Icon -->
              </span>
            </div>
            <a href="#">Forgot password?</a>

              <button type="submit" class="btn">Login</button>
              <div class="signup">
                <span class="signup">Don't have an account?
                 <label for="check">Signup</label>
                </span>
              </div>
       
            </form>   
          </div>

        <!---------Registration--------->

          <div class="form-block registration">
            <form  [formGroup]="registerform" (ngSubmit)="onSignUpSubmit()">
              <h2>Signup</h2>

              <div *ngIf="registersubmitted && rf['email'].errors" class="error-message">
                <div *ngIf="rf['email'].errors">Please provide proper email</div>
            </div>    
            <input type="email" autofocus formControlName="email" class="input-box" placeholder="Enter your email"
                [ngClass]="{ 'is-invalid': registersubmitted && rf['email'].errors }" />
            
            <div class="password-container">
                <input [type]="passwordVisibleSignUp ? 'text' : 'password'"  autocomplete="off"  placeholder="Create a password" formControlName="password" class="input-box"
                [ngClass]="{ 'is-invalid': registersubmitted && rf['password'].errors }" />
             
                <div  *ngIf="registerform.errors?.['mismatch']" class="error-message">
                  <div *ngIf="registerform.errors?.['mismatch']">Password mismatch</div>
                </div>
          
               <input type="password" placeholder="Confirm your password"  autocomplete="off"  formControlName="confirmpassword" class="input-box"
                  [ngClass]="{ 'is-invalid': registersubmitted && rf['confirmpassword'].errors }" />
                          
                
                <span class="toggle-password toggle-password-child2" (click)="togglePasswordSignUpVisibility()">
                  <span class="eye" *ngIf="!passwordVisibleSignUp">
                    <ion-icon name="eye-outline"></ion-icon>
                  </span>  <!-- Closed Eye Icon -->
                  <span class="eye-open" *ngIf="passwordVisibleSignUp">
                    <ion-icon name="eye-off-outline"></ion-icon>
                  </span>  <!-- Open Eye Icon -->
                </span>
             </div>


              <button type="submit" class="btn">Signup</button>

              <div class="signup">
              <span class="signup">Already have an account?
               <label for="check">Login</label>
              </span>
            </div>

          </form>
        </div>
    </div>
  }

  
  @if(this.showOtp){
    <app-otp  [email]="this.email"></app-otp>
  }

  </div>


</div>