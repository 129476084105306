import { CommonModule } from '@angular/common';
import { Component, computed, EventEmitter, Input, Output, signal } from '@angular/core';

@Component({
  selector: 'lib-fileupload',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './fileupload.component.html',
  styleUrl: './fileupload.component.css',
})
export class FileuploadComponent {
  @Input() chooserId!: number; // Input to uniquely identify each file chooser
  @Input() label!: string;
  @Output() uploadStatus = new EventEmitter<{ id: number; success: boolean; message: string, file?:File }>();

  imagePreview= signal<string | null>(null);
  errorMessage = signal<string | null>(null);
  isLoading = false;

  imgdata = computed(() => this.imagePreview());


  onFileSelected(event: Event): void {
    const fileInput = event.target as HTMLInputElement;
    this.errorMessage.set(null)
    if (fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      const validFormats = ['image/png','image/jpg', 'image/jpeg'];
      const MAX_SIZE_MB = 1;

      if (file.size > MAX_SIZE_MB * 1024 * 1024) {
        this.errorMessage.set(`File size exceeds ${MAX_SIZE_MB}MB. Please choose a smaller file.`);
        this.imagePreview.set(null);
        this.uploadStatus.emit({id: this.chooserId, success: false, message: this.errorMessage() as string });
        return;
      }

      // Validate file type
      if (!validFormats.includes(file.type)) {
        this.errorMessage.set('Invalid file format. Only .png and .jpeg are allowed.');
        this.imagePreview.set(null);
        this.uploadStatus.emit({ id: this.chooserId,success: false, message: this.errorMessage() as string });

        return;
      }

      this.imagePreview.set(null);
      this.isLoading = true;

      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview.set(reader.result as string);
        this.isLoading = false;
        this.uploadStatus.emit({ id: this.chooserId, success: true, message: 'File selected successfully!' , file: file});
      };

      reader.onerror = () => {
        this.errorMessage.set('Failed to load the image. Please try again.');
        this.isLoading = false;
        this.uploadStatus.emit({id: this.chooserId, success: false, message: this.errorMessage() as string });
      };
      reader.readAsDataURL(file);
      
    }
  }
  
}
