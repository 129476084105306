import { defer, EMPTY, Observable } from "rxjs";

export function fiif<T>(
  conditionFactory: () => boolean,
  trueCaseObservableFactory?: () => Observable<T>,
  falseCaseObservableFactory?: () => Observable<T>,
): Observable<T> {
  return defer(() =>
    conditionFactory()
      ? trueCaseObservableFactory
        ? trueCaseObservableFactory()
        : EMPTY
      : falseCaseObservableFactory
        ? falseCaseObservableFactory()
        : EMPTY,
  );
}