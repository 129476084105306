import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../../../../ui/src/lib/errordialog/errordialog.component';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private dialog: MatDialog) {}

  showErrorDialog(title: string, message: string): void {
    this.dialog.open(ErrorDialogComponent, {
      width: '600px',
      data: { title, message },
    });
  }
}