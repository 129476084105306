import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, Provider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideRouter, withRouterConfig } from '@angular/router';
import { DashboardEnvironmentType } from '../environments/env.type';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { LoggingInterceptor } from './interceptors/logging.interceptor';
import { SpinnerHttpInterceptor } from './interceptors/spinner.http.interceptor';

export const LoggingInterceptorProvider: Provider =
  { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true };

  export const authInterceptorProvider: Provider =
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true };

  export const errInterceptorProvider: Provider =
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true };

  export const spinnerHttpInterceptorProvider: Provider =
  { provide: HTTP_INTERCEPTORS, useClass: SpinnerHttpInterceptor, multi: true };


  export const AppConfig: IAppConfig = {    
    env: environment
  }; 

  export interface IAppConfig {
    env: DashboardEnvironmentType;
  }
  

  
export const appConfig: ApplicationConfig = {
  providers: [provideRouter(appRoutes, withRouterConfig({ onSameUrlNavigation: 'reload' })), provideHttpClient(
    withInterceptorsFromDi()), LoggingInterceptorProvider, authInterceptorProvider,
    spinnerHttpInterceptorProvider,
    { provide: "APP_CONFIG", useValue: AppConfig },
    { provide : "HOST_URL", useValue: AppConfig.env.baseUrl},
    importProvidersFrom([BrowserModule, BrowserAnimationsModule])


  ]
  };
