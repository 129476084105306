<div class="tb-container" id="tb-container-{{chooserId}}">
    <div class="tb-img-view">

        <div *ngIf="isLoading" class="loading">Loading image...</div>
        <div *ngIf="imagePreview() as preview" class="preview-section">
            <!-- <h4>{{label}}</h4>-->
            <img [id]="chooserId" [src]="preview" alt="Image Preview" class="preview-image" />
        </div>
        <p *ngIf="errorMessage" id="tb-file-upload-err-{{chooserId}}" class="error-message">{{ errorMessage() }}</p>
    </div>
    <label for="tb-file-upload-{{chooserId}}">Upload {{label}}</label>
    <input type="file" class="button" id="tb-file-upload-{{chooserId}}" accept="image/*"
        (change)="onFileSelected($event);" />
</div>