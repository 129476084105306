import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SessionModel } from 'lib-shared-models/models/session.model';
import { EnvironmentType } from 'lib-shared-utils/lib/shared-utils/type';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, defaultIfEmpty, map } from 'rxjs/operators';
import { HttpHelperService } from '../helper/http.helper.service';

const jwtToken = 'jwtToken';
const refreshToken = 'refreshToken';
const sessionToken = 'sessionToken';
export interface AuthResponse {
  jwtToken: string;
  refereshToken: string;
  session: SessionModel;
  isAccountVerified: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private currentUserSubject: BehaviorSubject<SessionModel>;
  public currentUser: Observable<SessionModel>;
  private env: EnvironmentType;

  constructor(
    private router: Router,
    private httpService: HttpHelperService,
    @Inject('APP_CONFIG')
    config: {
      env: EnvironmentType;
    },
  ) {
    this.currentUserSubject = new BehaviorSubject<SessionModel>(
      this.getSessionFromStorage(),
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.env = config.env;
  }

  public getCurrentLoginUser() {
    return of(this.currentUserSubject.value || new SessionModel());
  }

  public login(email: string, password: string): Observable<SessionModel> {
    const body = JSON.stringify({ email, password });
    return this.httpService.post<AuthResponse>(this.env.httpAuthUrl, body).pipe(
      map((response) => this.processLogin(response)),
      catchError(this.handleError),
    );
  }

  private processLogin(response: AuthResponse) {
    if (response && response.jwtToken) {
      return this.setSession(response);
    }
    throw new Error('No session details found');
  }

  // User login method
  public submitOtp(email: string, emailOtp: string): Observable<SessionModel> {
    const body = JSON.stringify({ email, emailOtp });
    return this.httpService
      .post<AuthResponse>(this.env.httpValidateEmailOtpUrl, body)
      .pipe(
        map((response) => this.processLogin(response)),
        catchError(this.handleError),
      );
  }

  public register(email: string, password: string): Observable<AuthResponse> {
    const body = JSON.stringify({ email, password });

    return this.httpService
      .post<AuthResponse>(this.env.httpSignUpEmailUrl, body)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError(this.handleError),
      );
  }

  public logout() {
    localStorage.removeItem(jwtToken);
    localStorage.removeItem(refreshToken);
    localStorage.removeItem(sessionToken);
    this.currentUserSubject.next(new SessionModel());
    this.router.navigate(['/login']);
  }

  public isLoggedIn(): boolean {
    return !!this.getJwtToken();
  }

  public updateSession(session: SessionModel) {
    localStorage.setItem(sessionToken, JSON.stringify(session));
  }

  private setSession(authResponse: AuthResponse): SessionModel {
    localStorage.setItem(jwtToken, authResponse.jwtToken);
    localStorage.setItem(refreshToken, authResponse.refereshToken);
    let session = new SessionModel();
    console.log('jwt ', JSON.parse(atob(authResponse.jwtToken.split('.')[1])));

    session = JSON.parse(atob(authResponse.jwtToken.split('.')[1]));
    localStorage.setItem(sessionToken, JSON.stringify(session));
    this.currentUserSubject.next(session);
    console.log(session);
    return session;
  }

  private getSessionFromStorage(): any {
    const session = localStorage.getItem(sessionToken);
    return session ? JSON.parse(session) : null;
  }

  private handleError(error: any) {
    let errorMessage = 'An error occurred';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else if (error.error && error.error.message) {
      errorMessage = error.error.message;
    }
    return throwError(() => new Error(errorMessage));
  }

  public setJwtToken(token: string) {
    localStorage.setItem(jwtToken, token);
  }

  public getJwtToken(): string | null {
    return localStorage.getItem(jwtToken);
  }

  private getRefreshToken(): string | null {
    return localStorage.getItem(refreshToken);
  }

  public refreshToken(): Observable<any> {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      return throwError(() => 'no referesh token');
    }
    return this.httpService
      .post<AuthResponse>(this.env.httpAuthRefreshTokenUrl, { refreshToken })
      .pipe(defaultIfEmpty(() => throwError(() => new Error('JWT expired'))));
  }
}
