import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { LoginComponent, LogoutComponent } from '@frontend/shared/ui/';
import { DriversInfoFormComponent } from './dashboard-component/dashboard-management/smart-components/drivers-info-form/drivers-info-form.component';
import { OrderInfoComponent } from './dashboard-component/dashboard-management/smart-components/order-info/order-info.component';
import { RestRegistrationComponent } from './dashboard-component/dashboard-management/smart-components/restaurant-registration/rest-registration.component';
import { UserInfoComponent } from './dashboard-component/dashboard-management/smart-components/user-info/user-info.component';
import { DashboradHomeComponent } from './dashboard-component/home-components/home/dashboard-home.component';
import { ControlPanelComponent } from './dashboard-component/partners-components/control-panel/control-panel.component';
import { DisplayDriversComponent } from './dashboard-component/partners-components/display-drivers/display-drivers.component';
import { DisplayUsersDetailsComponent } from './dashboard-component/partners-components/display-users-details/display-users-details.component';
import { OrderDetailsComponent } from './dashboard-component/partners-components/order-details/order-details.component';
import { AuthGuard } from './guards/auth.guard';

export const appRoutes: Route[] = [
  {
    path: 'home',
    component: DashboradHomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        component: ControlPanelComponent,
        path: '',
        canActivate: [AuthGuard],
      },
      {
        component: DisplayUsersDetailsComponent,
        path: 'users-panel',
        canActivate: [AuthGuard],
      },
      {
        component: UserInfoComponent,
        path: 'user/:id',
        canActivate: [AuthGuard],
      },
      {
        component: OrderDetailsComponent,
        path: 'orders-panel',
        canActivate: [AuthGuard],
      },
      {
        component: OrderInfoComponent,
        path: 'order/:id',
        canActivate: [AuthGuard],
      },
      {
        component: DisplayDriversComponent,
        path: 'drivers-panel',
        canActivate: [AuthGuard],
      },
      {
        component: DriversInfoFormComponent,
        path: 'driver/:id',
        canActivate: [AuthGuard],
      },
      {
        component: DriversInfoFormComponent,
        path: 'driver',
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
        component: RestRegistrationComponent,
        path: 'restaurant-registration',
        canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
