<form [formGroup]="registrationForm()" (ngSubmit)="onSubmit()">
  <div class="avatar-upload">
    <div class="avatar-edit">
      <input
        type="file"
        id="imageUpload"
        accept=".png, .jpg, .jpeg"
        #fileInput
        (change)="uploadFile($event)"
      />
    </div>
    <div class="avatar-preview">
      <div
        id="imagePreview"
        [style.backgroundImage]="'url(' + imageUrl() + ')'"
      ></div>
    </div>
  </div>

  <button type="submit" class="add_button" (click)="fileInput.click()">
    Add Cover
  </button>
  <p>.jpg, .jpeg, .png, .bmp, recommended size (512* 512) pixels</p>
</form>
