import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserModel } from '@frontend/shared/models';
import { UserApiService } from '../../../api-services/user.api.service';
import { UserListsComponent } from '../../dashboard-management/stateless-components/user-lists/user-lists.component';

@Component({
  selector: 'users-details',
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatButton,
    UserListsComponent,
  ],
  templateUrl: './display-users-details.component.html',
  styleUrl: './display-users-details.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayUsersDetailsComponent implements OnInit {
  constructor(
    private userApiService: UserApiService,
    private router: Router,
  ) {}
  public userlists = signal<UserModel[]>([]);
  public displayUserColumns = signal<string[]>([
    'name',
    'email',
    'mobileNo',
    'action',
  ]);

  ngOnInit(): void {
    this.userApiService.getUserList().subscribe({
      next: (users: UserModel[]) => {
        this.userlists.set([...users]);
      },
    });
  }
}
